import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useReceiptsList() {
  // Use toast
  const toast = useToast()

  const refReceiptListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'type', label: 'type', sortable: true },
    { key: 'count', sortable: false },
    { key: 'totalAmount', label: 'amount', sortable: true },
    { key: 'receiptDate', label: 'receipt Date', sortable: true },
    { key: 'status', label: 'status', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalReceipts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = ['All', 10, 25, 50, 100]
  const searchQuery = ref('')
  const fromDate = ref('')
  const toDate = ref('')
  const sortBy = ref('receiptDate')
  const isSortDirDesc = ref(true)
  const rangeFilter = ref(null)
  const statusFilter = ref(null)
  const totalReceiptAmount = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refReceiptListTable.value ? refReceiptListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReceipts.value,
    }
  })

  const getApprovals = computed(() => {
    const response =  store.getters['account-receipt/getApprovals']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      range: rangeFilter.value,
      status: statusFilter.value,
      from: fromDate.value,
      to: toDate.value
    })

    const { approvals, atotal, atotalAmount } = response
    totalReceipts.value = atotal
    totalReceiptAmount.value = atotalAmount

    return approvals
  })

  const refetchData = () => {
    refReceiptListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, rangeFilter, statusFilter, fromDate, toDate], () => {
    refetchData()
  })

  const approveReceipt = receiptEvent => {
    store.dispatch('account-receipt/approveReceipt', { receipt: receiptEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchReceipts()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      return response

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return error
    })
  }

  const fetchReceipts = (ctx, callback) => {
    store
      .dispatch('account-receipt/fetchApprovals')
      .then(() => {
        // const { Receipts, total } = response.data

        // callback(Receipts)
        
        // totalReceipts.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Receipts' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const approveReceipts = receiptEvent => {
    store.dispatch('account-receipt/approveReceipts', { receipt: receiptEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchReceipts()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      return response

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return error
    })
  }

  const resolveReceiptStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'approved') return 'success'
    if (status === 'cancelled') return 'danger'
    return 'secondary'
  }

  const resolveRangeVariant = type => {
    if (type === 'today') return 'Today'
    if (type === 'yesterday') return 'Yesterday'
    // if (type === 'this week') return 'This Week'
    // if (type === 'previous week') return 'Previous Week'
    if (type === 'this month') return 'This Month'
    if (type === 'previous month') return 'Previous Month'
    if (type === 'this year') return 'This Year'
    if (type === 'previous year') return 'Previous Year'
  }

  const resolveTypeVariant = type => {
    if (type === 'offering') return 'Offering'
    if (type === 'tithe') return 'Tithe'
    if (type === 'welfare') return 'Welfare'
    if (type === 'welfare dues') return 'Welfare Dues'
    if (type === 'harvest') return 'Harvest'
    if (type === 'harvest pledge') return 'Harvest Pledge'
    if (type === 'special offering') return 'Special Offering'
    if (type === 'thanksgiving') return 'Thanksgiving'
    if (type === 'others') return 'Others'
  }

  const resolveTypeRoleIcon = role => {
    if (role === 'offering') return 'ShieldIcon'
    if (role === 'tithe') return 'PocketIcon'
    if (role === 'welfare') return 'UsersIcon'
    if (role === 'welfare dues') return 'CalendarIcon'
    if (role === 'harvest') return 'ArchiveIcon'
    if (role === 'harvest pledge') return 'ThumbsUpIcon'
    if (role === 'special offering') return 'MailIcon'
    if (role === 'thanksgiving') return 'HeartIcon'
    if (role === 'others') return 'CreditCardIcon'
    return ''
  }

  return {
    // onSubmit,
    getApprovals,
    fetchReceipts,
    approveReceipts,
    tableColumns,
    perPage,
    currentPage,
    totalReceipts,
    dataMeta,
    perPageOptions,
    searchQuery,
    fromDate,
    toDate,
    sortBy,
    isSortDirDesc,
    refReceiptListTable,

    rangeFilter,
    statusFilter,
    resolveRangeVariant,
    resolveReceiptStatusVariant,
    refetchData,
    approveReceipt,
    resolveTypeVariant,
    resolveTypeRoleIcon,
    totalReceiptAmount
  }
}
